import React from 'react';
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import { Link } from "gatsby";

//import Navegacion from './nav';

const EnlaceHome = styled(Link)`
    color: #fff;
    text-align: center;
    text-decoration: none;
`;

const Footer = ({title}) => {

    const year = new Date().getFullYear();

    return ( 
        <> 
        <footer
            css={css`
                background-color: #30475e;
                margin-top: 5rem;
                padding: 1rem;
            `}
        >
            <div
                css={css`
                    max-width: 1200px;
                    margin: auto;

                    @media (min-width: 768px){
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                `}
            >
                
                <EnlaceHome
                    to='/'
                >
                    <h1
                    css={css`
                        font-size: 3rem;
                        font-family: 'Roboto';
                        color: #ececec;
                    `}
                    >OrlandoMelo.com</h1>
                </EnlaceHome>            
            </div>
        </footer>
        <p
            css={css`
                text-align: center;
                color: #ececec;
                background-color: #222831;
                margin: 0;
                pad: 1rem;
            `}
        >&copy;{year}, {` `}OrlandoMelo Blog  Todos los derechos reservados  </p>
        </>
    );
}
 
export default Footer;

