import React from 'react';
import { Link } from "gatsby"
import styled from '@emotion/styled';

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

    @media (min-width: 768px){
        padding: 0;
        flex-direction: row;
    }
`;

const NavLink = styled(Link)`
    color: #ececec;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    padding: .5rem;
    margin-right: 1rem;
    &:last-of-type{
        margin-right: 0;
    }
    &.pagina-actual{
        border-bottom: 2px solid #ececec;
    }
`;


const Navegacion = () => {
    return ( 
        <Nav>
            <NavLink 
                to={'/'}
                activeClassName="pagina-actual"
            >Inicio</NavLink>
            <NavLink 
                to={'/acerca-de-mi'}
                activeClassName="pagina-actual"
            >Acerca de Mi</NavLink>
            <NavLink 
                to={'/mis-publicaciones'}
                activeClassName="pagina-actual"
            >Mis Artículos</NavLink>
            <NavLink 
                to={'/mis-mensajes'}
                activeClassName="pagina-actual"
            >Mis Mensajes</NavLink>
        </Nav>
    );
}
 
export default Navegacion;