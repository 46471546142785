/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";

import Header from "./header"
import "../css/layout.css"
import Footer from './footer';
import Scroll from './scroll';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
                    
        }}
      >
        <main>{children}</main>
        
      </div>
      <Scroll showBelow={400} />
      <Footer/>
      <CookieConsent
            location="bottom"
            buttonText="Acepto"
            enableDeclineButton
            onDecline={() => {
                alert("Entendemos gracias");
            }}
            declineButtonText="No Acepto"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={29}
            sameSite="lax"
            >
            Este Sitio Web utiliza cookies para mejorar la experiencia del usuario.{" "}
            <span style={{ fontSize: "10px" }}> orlandomelo.com</span>
        </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
