import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Headroom from 'react-headroom';

import Navegacion from './navegacion';

const Header = ({ siteTitle }) => (
  <Headroom>
    <header
      css={css`
        background-color: #222831;
        padding: 1rem;
      `}      
    >
      <div
        css={css`
          max-width: 120rem;
          margin: 0 auto;
          text-align: center;

          @media (min-width: 768px){
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        `}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `#ececec`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <Navegacion />
      </div>
      
    </header>    
  </Headroom>
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
